import React, {useEffect, useRef} from 'react';
import lottie from 'lottie-web';
import animation from '../assets/animations/under-construction.json';

const contactEmail = 'mateusz.idzik@msisoftware.pl';

const Maintenance = () => {
  const anime = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: anime.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    });
    return () => lottie.stop();
  }, []);
  return <a href={`mailto:${contactEmail}`}>
    <div ref={anime}></div>
  </a>
};

export default Maintenance;
